.slide__all {
  position: relative;
  min-height: 100vh;
  margin-bottom: 4rem;

  .slide__wrapper {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }

  img {
    display: block;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  .swiper__all {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100%;
    height: 100vh;
  }
}

// ----------------------------------------- btn escape game
.btn__back__escape {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: #2b4355;
  color: white;
  display: flex;
  align-items: center;
  padding: 7px 15px;

  svg {
    margin-right: rem(10);
  }
}

// ----------------------------------------- eo btn escape game
.slide__content {
  padding: 3rem 4rem 2rem 4rem;
  h2 {
    margin-bottom: 2rem;
  }

  .items {
    display: grid;
    grid-template-columns: repeat(2, minmax(320px, 1fr));
    gap: 25px;
  }

  .item {
    text-align: center;
    border: 1px solid #dedede;
    padding: 1rem 0;
    img {
      display: block;
      margin: 0 auto 1rem auto;
      width: 76px;
      height: auto;
    }

    .item__title {
      // font-weight: bold;
      color: white;
      background-color: #2b4355;
      display: block;
      font-size: rem(20);
      padding: 5px 0;
      margin-bottom: 1rem;
    }

    .item__price {
      font-size: rem(20);
      font-weight: 500;
      span {
        margin-left: rem(10);
      }
    }
  }
}

.content__infos {
  margin-bottom: 2rem;
  background-color: #697e8f;
  color: white;
  padding: 0.5rem 1rem;
}

@include breakpoint(1250) {
  .slide__all .slide__wrapper {
    grid-template-columns: 1fr;
  }
  .slide__all .swiper__all,
  .slide__all img {
    height: 300px;
  }
}

@include breakpoint(850) {
  .slide__content {
    padding: 1rem;
  }
  .slide__content .items {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
