/*
************* BTN PRIMARY
* ===================================
*/

.page-101 .btn {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  touch-action: manipulation;
  border: none;
  color: white;
  height: initial;
  background-color: transparent;
  @include shadow-1;
  font-size: rem(25) !important;
  line-height: 1.5;
  font-weight: bold !important;
  text-transform: uppercase;
  transition: transform 150ms;

  &:hover {
    // background-color: rgba($color: $color-1, $alpha: 0.8);
    // transition: all 200ms;
    // border: 3px solid transparent;
  }

  &:active,
  &:focus {
    // background-color: $color-1;
    // color: white;
  }
}

.btn {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  touch-action: manipulation;
  border: none;
  // color: white;
  height: initial;
  background-color: transparent;
  font-size: rem(25) !important;
  line-height: 1.5;
  font-weight: bold !important;
  text-transform: uppercase;
  transition: transform 150ms;
}

.back__btn,
.next__btn {
  border: 0;
  outline: 0 !important;
  user-select: none;
  touch-action: manipulation;
  box-shadow: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: rem(25);
  font-weight: 600;
  margin-left: 1rem;
  transition: all 0.3s;
  &::after {
    margin-left: rem(10);
    display: inline-block;
    vertical-align: middle;
  }
}

.back__btn::after {
  content: url("/images/icons/icon-close.svg");
}

.next__btn::after {
  content: url("/images/icons/icon-arrow.svg");
}

.btn-2 {
  background-color: white;
  color: $color-1;
  border: 0;

  &:hover {
  }

  &:active,
  &:focus {
  }
}

button {
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  touch-action: manipulation;
  font-family: $font-normal;
}

/*
************* BTN SECONDARY
* ===================================
*/

.btn--outlined {
  background-color: $color-light;
  color: $color-1;

  &:hover {
  }

  &:active,
  &:focus {
  }
}

/*
************* DISABLED
* ===================================
*/
.btn[disabled] {
  cursor: default;
  opacity: 0.1;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-1;
    color: $color-light;
  }
}

/*
************* BTN TOP
* ===================================
*/

.btn__top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  transform: translateY(150px);
  z-index: 1000;

  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
  cursor: pointer;
  .btn__top__link {
    // background-color: $gray-6;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $color-light;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.55);
    border: 3px solid $color-light;
    transition: all 0.2s;

    i {
      font-size: 2rem;
    }
  }

  &:hover {
    .btn__top__link {
      background-color: $color-1;
      color: white;
    }
  }
}
.btn__top--active {
  transform: translateY(0);
  opacity: 1;
}

@include breakpoint(550) {
  .btn {
    padding: 4px 8px;
    font-size: rem(20) !important;
  }
}
