// --------------------------------------------------- Fox

.alert-success {
  .fox-messages {
    background-color: $validation;
  }
}

.fox-messages {
  background-color: $danger;
  color: white;
  padding: 1rem;
}

.close {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: rem(60);
  line-height: 1;
  color: white;
  cursor: pointer;
  opacity: 1;
}

// --------------------------------------------------- gix message
.gix_mess {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  top: 1rem;

  svg {
    display: block;
    margin: 0 auto 1rem auto;
  }

  .gix_mess_yes,
  .gix_mess_no,
  .gix_mess_info {
    padding: 1rem 2rem 1rem 1rem;
    border-radius: 7px;
    font-size: rem(20);
    margin: 1rem 0;
    text-align: center;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.16);
  }

  .gix_mess_yes {
    color: white;
    background-color: #15b713;
  }

  .gix_mess_no {
    color: white;
    background-color: #ff6262;
  }

  .gix_mess_info {
  }

  .gix_mess_title,
  .gix_mess_result {
  }

  .gix_mess_title {
    font-weight: bold;
  }

  .gix_mess_result {
  }
}

// --------------------------------------------------- Message joomla

.alert {
  position: relative;
}

.alert-heading {
  color: white !important;
}

.system__message,
#system-message {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  top: 1rem;

  .alert {
    padding: 1rem 2rem 1rem 1rem;
    border-radius: 7px;
    font-size: rem(20);
    margin: 1rem 0;
    // padding-right: 3rem;
    text-align: center;
    background-color: $validation;
    color: white;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.16);
  }

  .alert-danger,
  .alert-error,
  .alert-notice,
  .alert-warning {
    background-color: $danger;
    color: white;
  }

  .alert-message {
  }

  .close {
    // display: none;
  }
}

.message__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  svg,
  path {
    fill: white;
  }
}

// --------------------------------------------------- Message à côté de l'input

// .msg {
//   position: absolute;
//   left: calc(100% + 12px);
//   bottom: 10px;
//   z-index: 10;
//   line-height: 40px;
//   border-radius: 7px;
//   color: $color-light;
//   background-color: $color-dark;
//   box-shadow: 5px 10px 21px rgba($color: #000000, $alpha: 0.25);
//   @include arrow(12px, $color-dark, $color-dark);
// }

// .msg__content {
//   position: relative;
//   display: block;
//   padding: 0 rem(12);
//   white-space: nowrap;
// }

// ----------------------------------- error

// .msg--error {
//   background-color: $danger;
//   @include arrow(12px, $danger, $danger);
// }

// ----------------------------------- valid

// .msg--valid {
//   background-color: $color-1;
//   @include arrow(12px, $color-1, $color-1);
// }
