.page-114 #maincontent {
  padding-top: 10rem;
  text-align: center;
}

// -------------------------------------------- FOX CONTACT

.fox-container {
  text-align: center;

  padding: 2rem 1rem 6rem 1rem;
}

.fox-item-html {
  margin-bottom: 4rem;
}

// ------------------------ suppression des asterix
.required::after,
.asterisk::after {
  content: none !important;
}

// ------------------------ suppression des floats
.fox-item .controls {
  float: none !important;
}
// ------------------------ btn envoyer
.fox-item.fox-item-submit-fields .controls {
  width: 100% !important;

  .btn {
    margin-right: 0 !important;
    margin-top: 1rem;
    width: 100%;
    padding: rem(10) 0;
  }
}

// -------------------------------------------- MAP

/* ======================================================================  RESPONSIVE ============================= */

@include breakpoint(700) {
}
