.page-home #maincontent {
  display: none;
}

.nav__annex {
  position: absolute;
  z-index: 1000;
  right: 1rem;
  top: 220px;

  a {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    color: #1b2f3d;
    background-color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: rem(16);
    margin: 1rem 0;
    padding: 5px 15px;
    border-radius: 50px;
  }

  svg {
    margin-right: rem(10);
  }
}

@include breakpoint(1150) {
  .nav__annex {
    top: 150px;
  }
}

@include breakpoint(980) {
  .nav__annex {
    display: none;
  }
}
// ----------------------------------------- H E R O
#home-hero {
  position: relative;
  height: 100vh;
  background: url("/images/hero-slide.jpg");
  min-height: 100px;
}

.hero__content {
  position: absolute;
  z-index: 100;
  width: 100%;
  text-align: center;
  top: 320px;

  h1 {
    font-size: rem(90);
    font-weight: 800;
    text-transform: uppercase;
    color: white;
    @include shadow-black;
    line-height: 1;
  }

  .btn {
    margin-top: 4rem;
  }
}

.hero__slogan {
  display: block;
  font-size: rem(33);
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  @include shadow-black;
  letter-spacing: 0.04rem;
}

.hero__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(28, 36, 43);
  background: linear-gradient(
    45deg,
    rgba(28, 36, 43, 1) 0%,
    rgba(52, 88, 156, 1) 49%,
    rgba(5, 14, 23, 1) 100%
  );
  opacity: 0.19;
}

.page-101 .btn--reservation {
  position: fixed;
  z-index: 1000;
  right: rem(30);
  bottom: 0;
  transition: all 200ms;
  opacity: 0;
}

.page-101 .btn--reservation--active {
  transform: translateY(-30px);
  opacity: 1;
}

@include breakpoint(1500) {
  .hero__content h1 {
    font-size: rem(70);
  }
  .hero__slogan {
    font-size: 1.57rem;
  }
}

@include breakpoint(550) {
  .hero__content {
    top: 250px;
  }
  .hero__content h1 {
    font-size: 2.375rem;
  }
  .hero__slogan {
    font-size: 0.83rem;
  }
}

// ----------------------------------------- C O N C E P T

#le-concept {
  position: relative;

  background-color: #06111d;
  text-align: center;

  .items {
    position: relative;
    z-index: 100;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: baseline;
    gap: rem(50);
    justify-content: center;
    margin-top: $mg-4;

    .icons {
    }

    .icons__img {
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 160px;
    }
    .icons__title {
      display: block;
      font-size: rem(30);
      font-weight: bold;
    }
  }
}

@include breakpoint(980) {
  #le-concept .items .icons__img {
    height: 75px;
  }
  #le-concept .items .icons__title {
    font-size: rem(20);
  }
}

@include breakpoint(550) {
  #le-concept .items {
    grid-template-columns: 1fr;
  }
}

// ----------------------------------------- S A L L E S

#les-salles {
  background-color: #040b13;
}

.salle__title {
  font-size: rem(30);
  font-weight: bold;
  display: block;
  text-align: center;
  margin-bottom: $mg-4;
}
// ---------------------------------------------------- salle btn

//components -> btn

.salle__btn {
  text-align: right;
  padding-right: 4rem;
}

.next__btn,
.back__btn {
  opacity: 0;
}

.next__btn--active,
.back__btn--active {
  opacity: 1;
}

// ---------------------------------------------------- CARDS
.page-101 {
  .cards {
    position: relative;
    margin-top: $mg-3;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    // gap: rem(25);
    // padding: 0 4rem;
    grid-template-columns: 1fr;
    max-width: 95%;
    min-height: 600px;
  }

  .card {
    position: absolute;
    z-index: 1;
    // max-width: 875px;
    // width: 48%;
    width: 100%;
    top: 0;
    // height: 450px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.5s;

    &--hotel {
      left: 2rem;
    }

    &--medieval {
      // left: calc(50% + 2rem);
      left: 2rem;
    }

    &:hover .card__img {
      // transform: scale(1.08);
    }
  }

  .card--hotel {
    display: none;
  }

  .card__header {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 40px;

    min-height: 450px;
  }
  .salle--active {
    grid-template-columns: 1fr;
    .card--active {
      position: relative;
    }
  }

  //-------------------------------------------------------- ACTIVE

  .card--active {
    z-index: 10;
    max-width: 100%;
    cursor: default;
    width: 100%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 40px;
    overflow: visible;
    .card__img {
      width: 300px;
    }

    &.card--medieval {
      left: 2rem;
    }

    &:hover .card__img {
      transform: scale(1);
    }

    .card__title {
      display: none;
      font-size: rem(30);
      transition: all 0.5s;
      left: 1rem;
      &::before {
        transform: scale(0.7);
        margin-right: rem(8);
      }
    }

    .card__content {
      transition: all 0.5s ease 0.5s;
      opacity: 1;
      transform: translateY(0);
    }

    .card__stat,
    .card__access {
      transform: translateY(0);
      opacity: 1;
    }
    .card__stat--fouille {
      transition: all 0.5s ease 0.8s;
    }
    .card__stat--reflexion {
      transition: all 0.5s ease 0.9s;
    }
    .card__stat--manipulation {
      transition: all 0.5s ease 1s;
    }
    .card__stat--joueurs {
      transition: all 0.5s ease 1.1s;
    }
    .card__stat--temps {
      transition: all 0.5s ease 1.2s;
    }
    .card__stat--age {
      transition: all 0.5s ease 1.3s;
    }
    .card__stat--tarifs {
      transition: all 0.5s ease 1.4s;
    }
    .card__access {
      transition: all 0.5s ease 1.5s;
    }
  }

  .card__img {
    // position: relative;
    // display: block;
    position: absolute;
    width: 100%;
    max-width: 875px;
    height: 450px;
    top: 0;
    left: 0;
    transition: all 0.5s;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .card__title {
    display: none !important;
    position: absolute;
    top: rem(380);
    left: 2rem;
    font-size: rem(40);
    font-weight: 900;
    text-transform: uppercase;
    display: flex;
    //   align-items: center;
  }
  .card--hotel {
    display: none !important;
    .card__img {
      background-image: url("/images/hotel.jpg");
    }
    .card__title::before {
      content: url("/images/icons/icon-hotel.svg");
      margin-right: rem(15);
    }
  }
  .card--medieval {
    .card__img {
      background-image: url("/images/medieval.jpg");
    }
    .card__title::before {
      content: url("/images/icons/icon-medieval.svg");
      margin-right: rem(15);
    }
  }

  //-------------------------------------------------------- content

  .card__content {
    opacity: 0;
    transform: translateY(20px);

    .btn {
      margin-top: $mg-4;
      font-size: rem(40);
    }
  }

  .card__content__title {
    display: block;
    font-size: rem(30);
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: $mg-2;
  }

  .card__description {
    font-style: italic;
  }

  //-------------------------------------------------------- stats

  .card__stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 1rem;
    // height: 500px;
  }

  .card__stat {
    box-shadow: 0 10px 21px rgba(29, 82, 210, 1);
    border-radius: 7px;
    display: grid;
    gap: 10px;
    align-items: center;
    padding: 1rem;
    opacity: 0;
    transform: translateY(50px);
  }

  .stat__title {
    display: flex;
    align-items: center;
    font-size: rem(18);
    font-weight: bold;
    text-transform: uppercase;
  }

  .stat__count {
    font-size: rem(35);
    font-weight: bold;
    color: #719bff;
    padding-left: 1rem;
    text-shadow: 0 5px 10px #1d52d2;
  }

  .card__stat--tarifs {
    grid-column: 1 / span 2;
  }

  .card__stat .stat__title::before {
    margin-right: rem(10);
  }

  .card__stat--fouille .stat__title::before {
    content: url("/images/icons/icon-fouille.svg");
  }
  .card__stat--reflexion .stat__title::before {
    content: url("/images/icons/icon-reflexion.svg");
  }
  .card__stat--manipulation .stat__title::before {
    content: url("/images/icons/icon-manipulation.svg");
  }
  .card__stat--joueurs .stat__title::before {
    content: url("/images/icons/icon-joueurs.svg");
  }
  .card__stat--temps .stat__title::before {
    content: url("/images/icons/icon-temps.svg");
  }
  .card__stat--age .stat__title::before {
    content: url("/images/icons/icon-age.svg");
  }
  .card__stat--tarifs .stat__title::before {
    content: url("/images/icons/icon-tarifs.svg");
  }

  //--------------------------------------------------------  users

  .stat__users {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    // gap: 10px;
  }

  .stat__user {
    text-align: center;
  }

  .stat__user::before {
    display: block;
  }

  .stat__user--2::before {
    content: url("/images/icons/icon-user-2.svg");
  }
  .stat__user--3::before {
    content: url("/images/icons/icon-user-3.svg");
  }
  .stat__user--4::before {
    content: url("/images/icons/icon-user-4.svg");
  }
  .stat__user--5::before {
    content: url("/images/icons/icon-user-5.svg");
  }
  .stat__user--6::before {
    content: url("/images/icons/icon-user-6.svg");
  }

  //------------------------------------------------------- access

  .card__access {
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    opacity: 0;
    transform: translateY(50px);
  }

  .card__access::before {
    content: url("/images/icons/icon-access.svg");
    margin-right: rem(10);
  }
}
//------------------------------------------------------- eo content

@include breakpoint(1500) {
  .stat__title {
    font-size: 0.8rem;
  }
  .stat__count {
    font-size: 1.7rem;
  }
  .stat__user::before {
    transform: scale(0.8);
  }
}

@include breakpoint(1250) {
  .page-101 {
    .card--active {
      grid-template-columns: 1fr;
    }
    .cards {
      // min-height: 1300px;
    }
    .card__title {
      font-size: 1.7rem;
    }
    .card--hotel .card__title::before {
      transform: scale(0.8);
    }
  }
}

@include breakpoint(880) {
  .page-101 {
    .cards {
      grid-template-columns: 1fr;
      max-width: 100%;
    }
    .card {
      // position: relative;
      width: 90%;
      left: 1rem;
      height: 300px;
      // right: 0;
    }
    .card__title {
      top: 235px;
    }
    .card--medieval {
      top: 320px;
    }
    .card--active {
      height: auto;
      &.card--medieval {
        top: initial;
        left: 1rem;
      }
      .card__title {
        top: 350px;
      }
    }
    .card__header {
      grid-template-columns: 1fr;
    }
    .card--active {
      width: 95%;
    }
    .card--active .card__img {
      width: 100%;
      display: block;
      position: relative;
    }
    .card__stats {
      padding: 0;
    }
    .card--hotel {
      left: 1rem;
    }
  }
}

// ----------------------------------------- M A P
.home-map {
  position: relative;
  background-color: #040b13;
  padding-bottom: $mg-8;
}

.home-map__view {
  position: relative;
  z-index: 10;
  height: 600px;
  width: 90%;
  max-width: 1500px;
  margin: auto;
  box-shadow: 0 5px 21px rgba($color: #1d52d2, $alpha: 1);
}

.home-map__infos {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 10000;
  background-color: #1b2f3d;
  border: 1px solid white;
  padding: 1rem 2rem;
  border-radius: 6px;
}

.home-map__title {
  display: flex;
  align-items: center;
  @include shadow-1;
  color: var(--color-1);
  font-size: rem(18);
  text-transform: uppercase;
  //   margin-bottom: $mg-1;
  &::before {
    content: url("/images/icons/icon-marker.png");
    margin-right: rem(10);
  }
}

address {
  font-style: normal;
}

.address__title {
  display: block;
  display: block;
  font-weight: bold;
  font-size: rem(20);
}

.address__infos {
  font-style: rem(20);
}

#map {
  height: 100%;
}

@include breakpoint(800) {
  .home-map {
    padding-bottom: 11rem;
  }
  .home-map__infos {
    top: 0%;
    left: 0;
    position: relative;
  }
  .home-map__view {
    height: 450px;
  }
}

// ----------------------------------------- C O N T E N T

.home-content {
  margin-top: 10rem;
  .item {
    margin-bottom: 5rem;
  }
}

@include breakpoint(550) {
  .home-content {
    margin-top: 4rem;
  }
}
