/*
************* COLORS
* ==================
*/

$color-1: #719bff;
$color-2: #719bff;
$color-3: #112529;

$color-light: #ffffff;
$color-dark: #272525;

$gray-1: #f9f9f9;
$gray-2: #f5f5f5;
$gray-3: #f1f1f1;
$gray-4: #eaeaea;
$gray-5: #bebebe;

$danger: #ff4c4c;
$validation: #50c35f;

.color-1 {
  color: $color-1;
}

.color-2 {
  color: $color-2;
}

/*
************* FONT-FAMILY
* ===================================
*/

// fonts à placer dans dossier CSS -> Fonts

$font-normal: "WorkSans";
$font-titre: "WorkSans";
$font-awe: "Font Awesome 5 Free";
/*
************* FONT-SIZE
* ===================================
*/

$font-size-base: 16px !default;

/*
************* ESPACE
* ===================================
*/

// Base
$mg-1: rem(8) !default;
$mg-2: rem(16) !default;
$mg-3: rem(32) !default;
$mg-4: rem(48) !default;
$mg-5: rem(64) !default;
$mg-6: rem(80) !default;
$mg-7: rem(96) !default;
$mg-8: rem(112) !default;
$mg-9: rem(128) !default;
$mg-section: rem(90) !default;

.section {
  padding: rem(90) 0;
}

.mg-1 {
  margin-bottom: $mg-1;
}
.mg-2 {
  margin-bottom: $mg-2;
}
.mg-3 {
  margin-bottom: $mg-3;
}
.mg-4 {
  margin-bottom: $mg-4;
}
.mg-5 {
  margin-bottom: $mg-5;
}
.mg-6 {
  margin-bottom: $mg-6;
}
.mg-7 {
  margin-bottom: $mg-7;
}
.mg-8 {
  margin-bottom: $mg-8;
}
.mg-9 {
  margin-bottom: $mg-9;
}

/*
************* TIPS
* ===================================
*/

.nowrap {
  white-space: nowrap;
}
