.header__mobile__logo {
  display: none;
}

.header {
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  .container {
    width: 100%;
  }

  .menu__main__desktop ul {
    width: 1200px;
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    align-items: center;
  }

  .menu__left,
  .menu__right {
    display: inline-flex;
  }
  .menu__center {
    text-align: center;
  }

  .menu__left {
    justify-content: flex-end;
  }

  .menu__item {
    color: white;
    font-size: rem(25);
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 rem(10);
    padding: 1rem 0;
    transition: text-shadow 200ms;

    &:hover {
      @include shadow-1;
    }
  }
}

@include breakpoint(1250) {
  .header {
    width: 100%;
  }
  .header .menu__main__desktop ul {
    width: 100%;
  }
}

@include breakpoint(1150) {
  .header .menu__item {
    font-size: rem(20);
  }
  .header .menu__center img {
    width: 120px;
  }
}

@include breakpoint(980) {
  .header {
    padding: 1rem 2rem;
    // position: relative;
    z-index: 9999;
    top: auto;
    left: 0%;
    transform: initial;
  }
  .header__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .header__desktop {
    display: none;
  }
  .header__mobile__logo {
    display: block;
    img {
      max-height: 100px;
    }
  }
}

@include breakpoint(550) {
  .header {
    padding: 1rem;
  }
}
