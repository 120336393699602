%form {
  display: block;
  position: relative;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  width: 100% !important;

  //----------------------------- STYLE
  font-family: $font-normal;
  background-color: transparent;
  box-shadow: none;
  color: white;
  border: 1px solid $color-3;
  font-size: rem(20) !important;
  // padding: 0 rem(50) 0 rem(16);
  padding-left: 15px !important;
  transition: box-shadow 200ms ease;

  &:hover {
    box-shadow: 0 0 0 1px rgb(36, 36, 36), 0 0 0 2px $color-1;
    background-color: transparent;
  }

  &:focus {
    border: 1px solid $color-1;
    box-shadow: 0 0 0 0.1rem rgb(66, 65, 65), 0 0 0 0.25rem $color-1;
  }
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-background-clip: text;
  font-size: 1.2rem;
}

input:-webkit-autofill::first-line {
  font-size: rem(20);
}

// ------------------------------------------------ checkbox hikashop
.hikashop_filter_checkbox {
  display: inline-flex;
  align-items: center;
}

input[type="radio"],
input[type="checkbox"] {
  position: relative;
  background-color: white;
  display: inline-flex;
  vertical-align: middle;
  border: 0;
  padding: 0;
  box-shadow: none;
  width: 25px;
  height: 25px;

  &:checked {
    &::after {
      content: "\f00c";
      font-family: "Font Awesome\ 5 Free";
      position: absolute;
      line-height: 25px;
      text-align: center;
      width: 100%;
      font-size: 1.2rem;
      color: $color-1;
    }
  }
  &:hover {
    border: 0;
  }
  &:focus {
    border: 0;
    background-color: white;
  }
}

.form-group,
.control-group {
  position: relative;
  margin-bottom: $mg-2;
}

fieldset {
  border: 0;
}

legend {
  font-size: rem(22);
  margin-bottom: 1rem;
}

.btn--submit {
  width: 100%;
}

label {
  position: relative;
  display: block;
  margin-left: rem(10);
  margin-bottom: rem(5);
  text-transform: uppercase;
  // pointer-events: none;
  font-size: rem(15);
  // color: $gray-8;
  font-weight: 300;
}

input {
  @extend %form;
  height: rem(50) !important;
}
// --------------------------- SELECT - supprimer arrow sur ie
select::-ms-expand {
  display: none;
}
select {
  @extend %form;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: rem(60);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMXJlbSIgd2lkdGg9IjFyZW0iIGZpbGw9IiMyMjIwMjAiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTggMTEuNWExIDEgMCAwMS0uNzEtLjI5bC01LTVhMSAxIDAgMDExLjQyLTEuNDJMOCA5LjA5bDQuMjktNC4zYTEgMSAwIDExMS40MiAxLjQybC01IDVhMSAxIDAgMDEtLjcxLjI5eiIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: right 1rem center;
}

textarea {
  @extend %form;
  padding-top: 10px;
  max-width: 100%;
  max-height: 250px;
  line-height: 1.5 !important;
  resize: none;
}

::-webkit-input-placeholder,
::-moz-placeholder,
::-ms-input-placeholder {
  font-size: rem(14);
}
