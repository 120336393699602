.slide__content {
  .list-item {
    margin-bottom: 4rem;
    li {
      font-size: rem(20);
      color: #697e8f;
      padding: 5px 0;
      display: flex;
      align-items: center;
    }
    i {
      font-size: rem(30);
      //   color: #697e8f;
      margin-right: rem(8);
    }
  }

  .salle-tarif {
    .salle-tarif__title {
      font-size: rem(30);
    }
    li {
      font-size: rem(25);
    }
    .day {
      display: inline-flex;
      font-weight: bold;
      min-width: 200px;
      border-bottom: 1px solid $gray-4;
    }
    .price {
    }
  }
}

@include breakpoint(550) {
  .slide__content .salle-tarif li {
    font-size: rem(20);
  }
  .slide__content .salle-tarif .day {
    min-width: 150px;
  }
}
