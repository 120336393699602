html {
  font-size: $font-size-base;
}

body {
  font-family: $font-normal;
  line-height: 1.5;
  color: $color-dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizelegibility;
}

/*
************* TITRE
* ===================================
*/

// ------------ Custom
.title__main {
  text-align: center;
  margin-bottom: $mg-4;

  .title__slogan {
    display: block;
    font-size: rem(18);
    font-weight: 300;
    color: $color-3;
    text-transform: uppercase;
  }
}
h1 {
  font-family: $font-titre;
  line-height: 1.5;
  font-size: rem(40);
}
h2 {
  font-family: $font-titre;
  font-size: rem(40);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: $mg-6;
}

.page-101 h2::after {
  content: "";
  display: block;
  width: 100px;
  height: 10px;
  border: 2px solid white;
  margin: auto;
  @include box-shadow-1;
}
// ------------ EO H2
h3 {
  font-size: rem(30);
  font-family: $font-titre;
  font-weight: bold;
  margin-bottom: 3rem;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
}

h4 {
  line-height: 1.5;
}

h5 {
  line-height: 1.5;
}

h6 {
  line-height: 1.5;
}

/*
************* TEXTE
* ===================================
*/

p {
  font-size: rem(18);
  font-weight: 400;
  line-height: 1.7;
  font-weight: 300;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    //color: $color-1;
    //transition: color 200ms ease-in-out;
  }
}

address {
  font-style: normal;
}

// ===================================== RESPONSIVE

@include breakpoint(980) {
  // Title
  h1 {
    font-size: rem(24);
  }
  h2 {
    font-size: rem(24);
  }
  h3 {
    font-size: rem(20);
  }
}
