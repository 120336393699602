.menu__main {
  display: none;
}
//------------------------------------------------------ bouton menu
#menu__btn {
  display: none;
  position: relative;
  cursor: pointer;
  z-index: 9999;
  outline: 0;
  background-color: transparent;
  border: 0;
  .menu__mobile__title {
    display: inline-flex;
    text-transform: uppercase;
    color: $color-1;
    line-height: 0;
    font-family: $font-normal;
  }
}

.menu__bar {
  position: relative;
  display: block;
  width: 40px;
  height: 3px;
  background-color: $color-1;
  margin-bottom: 7px;
  border-radius: 2px;
  transition: all 150ms;
}

#menu__btn.menu__btn--active {
  position: relative;
  .menu__bar--1 {
    opacity: 0;
  }
  .menu__bar--2 {
    transform: rotate(45deg);
  }
  .menu__bar--3 {
    transform: rotate(-45deg);
    top: -10px;
  }
}

//------------------------------------------------------ RESPONSIVE
@include breakpoint(980) {
  #menu__btn {
    display: inline-block;
  }
  .menu__main {
    position: fixed;
    top: 0;
    right: -100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-align: center;
    width: 100vw;
    height: 100vh;
    // background-color: $gray-4;
    transition: all 200ms;
    z-index: -1;
    // -------------------------- ul
    ul {
      display: flex;
      flex-direction: column;
    }
    // -------------------------- li
    li {
      padding: rem(10) 0;
    }
    //------------------------------------------------------ a
    a {
      font-size: rem(25);
      font-weight: 500;
      text-transform: uppercase;
      transition: all 200ms;
    }

    li.active a {
      color: $color-1;
    }
    //------------------------------------------------------ hover
    a:hover {
      color: $color-1;
    }
  }
  // -------------------------- menu active
  .header__menu--active .menu__main {
    position: fixed;
    background-color: #040b13;
    right: 0;
    z-index: 9998;
  }
  .header__menu {
    text-align: right;
  }
}

@include breakpoint(550) {
  .menu__main a {
    font-size: rem(16);
  }
}
