.page-404 {
  margin: 5rem 0;
  text-align: center;
}

.title__404 {
  font-size: rem(80);
  text-transform: uppercase;
  font-weight: bold;
  color: $color-1;
}

.description__404 {
  font-size: rem(30);
}

.btn--404 {
  margin-top: 2rem;
}
