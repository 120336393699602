.self-stockage__price {
  padding: 5rem rem(8);

  table {
    width: 100%;
    text-align: center;
    // table-layout: fixed;
  }
  thead tr {
    background-color: #2b4355 !important;
    color: white;
  }
  thead th {
    padding: 15px 0;
    // min-width: 75px;
  }
  tr:nth-child(even) {
    background: $gray-2;
  }
  tr:nth-child(odd) {
    background: #fff;
  }
  td {
    padding: 10px 0;
  }
}

@include breakpoint(850) {
  .self-stockage__price thead th {
    font-weight: normal;
    font-size: rem(12);
  }
}

@include breakpoint(550) {
  .self-stockage__price thead th {
    font-size: rem(10);
  }
  .self-stockage__price td {
    font-size: rem(10);
  }
}
