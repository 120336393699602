html {
  scroll-behavior: smooth;
  height: 2000px;
}

body {
  word-break: break-word;

  // padding-bottom: 10rem;
}

.page-101 {
  background-color: #040b13;
  color: white;
}

#wrapper {
  position: relative;
  // width: 100%;
  margin: auto;
  min-height: 100vh;
  // overflow: hidden;
}

.container {
  width: rem(1000);
  padding: 0 1rem;
  margin: auto;
}

.section {
  padding: rem(200) 0;
}

@include breakpoint(1180) {
  .container {
    width: 100%;
  }
}

@include breakpoint(550) {
}
