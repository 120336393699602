.gradient {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
}

.gradient--hero {
  background: rgb(6, 17, 29);
  background: linear-gradient(
    180deg,
    rgba(6, 17, 29, 0) 0%,
    rgba(6, 17, 29, 1) 100%
  );
}

.gradient--concept {
  background: rgb(4, 11, 19);
  background: linear-gradient(
    180deg,
    rgba(4, 11, 19, 0) 0%,
    rgba(4, 11, 19, 1) 100%
  );
}

.gradient--salles {
  background: rgb(27, 47, 61);
  background: linear-gradient(
    180deg,
    rgba(27, 47, 61, 0) 0%,
    rgba(27, 47, 61, 1) 100%
  );
}
