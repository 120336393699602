::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  background: $color-1;
  border-radius: 7px;
}
